$amplify-button-bg-color: rgba(#fff, 0.2);
$amplify-button-border: rgba(#fff, 0.2);
$amplify-button-text-color: #8cd;
$amplify-button-hover-text-color: #0fe;
$input-bg-color: rgba(#fff, 0.05);
$input-border-color: rgba(#fff, 0.05);
$input-active-border-color: rgba(255, 255, 255, 0.2);
$input-active-box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
$input-active-bg-color: rgba(255, 255, 255, 0.2);
$input-focus-border-color: rgba(#0fa, 0.5);
$input-focus-bg-color: rgba(100, 225, 255, 0.2);
$input-focus-text-color: #aff;
$amplify-button-border-color-primary: #0a6;
$amplify-button-text-color-primary: #eee;
$amplify-button-active-border-color-primary: #0fa;
$amplify-button-active-text-color-primary: #8ff;
$amplify-button-active-box-shadow-primary: 0 0 1rem rgba(0, 0, 0, 0.5);
$amplify-button-border-color-dis-primary: #eee;
$amplify-button-text-color-dis-primary: #eee;
$amplify-field-group-text-color-error: #f8a;
$amplify-alert-amplify-button-hover-text-color-error: #fff;
$amplify-field-group-text-color-input: rgba(#fff, 0.5);
$amplify-tabs-item-text-shadow-color-glow: #8ff;
$amplify-tabs-item-text-color-glow: #8ff;
$box-shadow-panel: 0 0 1rem rgba(#000, 0.3);
$bg-color-panel: rgba(#222, 0.3);
$border-color-panel: rgba(#fff, 0.1);
$amplify-field-group-placeholder-text-color: #aaa;
$amplify-field-group-focus-text-color: #fff;
$text-color-tab-inactive: #aaa;
$amplify-button-primary-gradient-dir: to left;
