@media (prefers-color-scheme: dark) {
  @import "vars";
  @import "mixins";
  @import "amplify";
}

@media (prefers-color-scheme: light) {
  @import "vars_light";
  @import "mixins";
  @import "amplify";
}
