.night-background {
  color: inherit;
}

.night-background .scenery {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#24404e),
    to(#04080f)
  );
  background: linear-gradient(to top, #24404e 0%, #04080f 100%);
  background-repeat: no-repeat;
  overflow: hidden;
}

.night-background .scenery .sunset-sun {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  min-width: 400%;
  background: radial-gradient(
    ellipse at 25% 100%,
    rgba(255, 160, 60, 0.9) 0%,
    rgba(200, 160, 140, 0.5) 10%,
    rgba(110, 100, 140, 0.7) 20%,
    rgba(50, 90, 120, 0.5) 40%,
    rgba(36, 64, 78, 0.1) 60%,
    #04080f 100%
  );
}

.night-background .scenery .sunset {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(200, 160, 140, 0.9)),
    color-stop(20%, rgba(110, 100, 140, 0.7)),
    color-stop(40%, rgba(50, 90, 120, 0.3)),
    color-stop(60%, rgba(36, 64, 78, 0.3)),
    to(#04080f)
  );
  background: linear-gradient(
    to top,
    rgba(200, 160, 140, 0.9) 0%,
    rgba(110, 100, 140, 0.7) 20%,
    rgba(50, 90, 120, 0.3) 40%,
    rgba(36, 64, 78, 0.3) 60%,
    #04080f 100%
  );
}

.night-background .scenery .star {
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background: white;
  -webkit-box-shadow: 0 0 2px #08f;
  box-shadow: 0 0 2px #08f;
  -webkit-animation: star 1s ease-in-out infinite alternate;
  animation: star 1s ease-in-out infinite alternate;
}

.night-background .scenery .star:nth-child(1) {
  opacity: 0.39;
  top: calc(5%);
  left: calc(78%);
  width: calc(3px);
  height: calc(3px);
  -webkit-animation-delay: 1135ms;
  animation-delay: 1135ms;
}

.night-background .scenery .star:nth-child(2) {
  opacity: 0.37;
  top: calc(12%);
  left: calc(20%);
  width: calc(3px);
  height: calc(3px);
  -webkit-animation-delay: 2470ms;
  animation-delay: 2470ms;
}

.night-background .scenery .star:nth-child(3) {
  opacity: 0.29;
  top: calc(26%);
  left: calc(22%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 1349ms;
  animation-delay: 1349ms;
}

.night-background .scenery .star:nth-child(4) {
  opacity: 0.4;
  top: calc(45%);
  left: calc(3%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 1385ms;
  animation-delay: 1385ms;
}

.night-background .scenery .star:nth-child(5) {
  opacity: 0.1;
  top: calc(44%);
  left: calc(60%);
  width: calc(3px);
  height: calc(3px);
  -webkit-animation-delay: 1909ms;
  animation-delay: 1909ms;
}

.night-background .scenery .star:nth-child(6) {
  opacity: 0.05;
  top: calc(11%);
  left: calc(66%);
  width: calc(3px);
  height: calc(3px);
  -webkit-animation-delay: 2000ms;
  animation-delay: 2000ms;
}

.night-background .scenery .star:nth-child(7) {
  opacity: 0.31;
  top: calc(23%);
  left: calc(74%);
  width: calc(3px);
  height: calc(3px);
  -webkit-animation-delay: 1646ms;
  animation-delay: 1646ms;
}

.night-background .scenery .star:nth-child(8) {
  opacity: 0.38;
  top: calc(27%);
  left: calc(48%);
  width: calc(3px);
  height: calc(3px);
  -webkit-animation-delay: 2147ms;
  animation-delay: 2147ms;
}

.night-background .scenery .star:nth-child(9) {
  opacity: 0.08;
  top: calc(9%);
  left: calc(36%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 2494ms;
  animation-delay: 2494ms;
}

.night-background .scenery .star:nth-child(10) {
  opacity: 0.09;
  top: calc(40%);
  left: calc(67%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 1392ms;
  animation-delay: 1392ms;
}

.night-background .scenery .star:nth-child(11) {
  opacity: 0.01;
  top: calc(43%);
  left: calc(30%);
  width: calc(3px);
  height: calc(3px);
  -webkit-animation-delay: 1467ms;
  animation-delay: 1467ms;
}

.night-background .scenery .star:nth-child(12) {
  opacity: 0.5;
  top: calc(36%);
  left: calc(34%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 2038ms;
  animation-delay: 2038ms;
}

.night-background .scenery .star:nth-child(13) {
  opacity: 0.43;
  top: calc(4%);
  left: calc(78%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 2732ms;
  animation-delay: 2732ms;
}

.night-background .scenery .star:nth-child(14) {
  opacity: 0.19;
  top: calc(15%);
  left: calc(4%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 2160ms;
  animation-delay: 2160ms;
}

.night-background .scenery .star:nth-child(15) {
  opacity: 0.26;
  top: calc(41%);
  left: calc(46%);
  width: calc(3px);
  height: calc(3px);
  -webkit-animation-delay: 1707ms;
  animation-delay: 1707ms;
}

.night-background .scenery .star:nth-child(16) {
  opacity: 0.05;
  top: calc(5%);
  left: calc(44%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 2488ms;
  animation-delay: 2488ms;
}

.night-background .scenery .star:nth-child(17) {
  opacity: 0.47;
  top: calc(19%);
  left: calc(74%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 2546ms;
  animation-delay: 2546ms;
}

.night-background .scenery .star:nth-child(18) {
  opacity: 0.21;
  top: calc(39%);
  left: calc(85%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 1102ms;
  animation-delay: 1102ms;
}

.night-background .scenery .star:nth-child(19) {
  opacity: 0.34;
  top: calc(12%);
  left: calc(95%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 2846ms;
  animation-delay: 2846ms;
}

.night-background .scenery .star:nth-child(20) {
  opacity: 0.13;
  top: calc(46%);
  left: calc(15%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 2543ms;
  animation-delay: 2543ms;
}

.night-background .scenery .star:nth-child(21) {
  opacity: 0.47;
  top: calc(25%);
  left: calc(27%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 2886ms;
  animation-delay: 2886ms;
}

.night-background .scenery .star:nth-child(22) {
  opacity: 0.1;
  top: calc(36%);
  left: calc(65%);
  width: calc(3px);
  height: calc(3px);
  -webkit-animation-delay: 2901ms;
  animation-delay: 2901ms;
}

.night-background .scenery .star:nth-child(23) {
  opacity: 0.48;
  top: calc(48%);
  left: calc(53%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 2973ms;
  animation-delay: 2973ms;
}

.night-background .scenery .star:nth-child(24) {
  opacity: 0.02;
  top: calc(43%);
  left: calc(66%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 1094ms;
  animation-delay: 1094ms;
}

.night-background .scenery .star:nth-child(25) {
  opacity: 0.35;
  top: calc(33%);
  left: calc(63%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 2389ms;
  animation-delay: 2389ms;
}

.night-background .scenery .star:nth-child(26) {
  opacity: 0.29;
  top: calc(12%);
  left: calc(24%);
  width: calc(3px);
  height: calc(3px);
  -webkit-animation-delay: 2101ms;
  animation-delay: 2101ms;
}

.night-background .scenery .star:nth-child(27) {
  opacity: 0.18;
  top: calc(45%);
  left: calc(33%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 2801ms;
  animation-delay: 2801ms;
}

.night-background .scenery .star:nth-child(28) {
  opacity: 0.34;
  top: calc(25%);
  left: calc(39%);
  width: calc(3px);
  height: calc(3px);
  -webkit-animation-delay: 2419ms;
  animation-delay: 2419ms;
}

.night-background .scenery .star:nth-child(29) {
  opacity: 0.32;
  top: calc(21%);
  left: calc(4%);
  width: calc(3px);
  height: calc(3px);
  -webkit-animation-delay: 1277ms;
  animation-delay: 1277ms;
}

.night-background .scenery .star:nth-child(30) {
  opacity: 0.14;
  top: calc(35%);
  left: calc(63%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 1393ms;
  animation-delay: 1393ms;
}

.night-background .scenery .star:nth-child(31) {
  opacity: 0.3;
  top: calc(41%);
  left: calc(6%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 1306ms;
  animation-delay: 1306ms;
}

.night-background .scenery .star:nth-child(32) {
  opacity: 0.44;
  top: calc(26%);
  left: calc(24%);
  width: calc(3px);
  height: calc(3px);
  -webkit-animation-delay: 2044ms;
  animation-delay: 2044ms;
}

.night-background .scenery .star:nth-child(33) {
  opacity: 0.21;
  top: calc(34%);
  left: calc(31%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 2305ms;
  animation-delay: 2305ms;
}

.night-background .scenery .star:nth-child(34) {
  opacity: 0.14;
  top: calc(17%);
  left: calc(51%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 2950ms;
  animation-delay: 2950ms;
}

.night-background .scenery .star:nth-child(35) {
  opacity: 0.26;
  top: calc(34%);
  left: calc(59%);
  width: calc(3px);
  height: calc(3px);
  -webkit-animation-delay: 2867ms;
  animation-delay: 2867ms;
}

.night-background .scenery .star:nth-child(36) {
  opacity: 0.01;
  top: calc(40%);
  left: calc(19%);
  width: calc(3px);
  height: calc(3px);
  -webkit-animation-delay: 1286ms;
  animation-delay: 1286ms;
}

.night-background .scenery .star:nth-child(37) {
  opacity: 0.27;
  top: calc(45%);
  left: calc(34%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 2249ms;
  animation-delay: 2249ms;
}

.night-background .scenery .star:nth-child(38) {
  opacity: 0.22;
  top: calc(45%);
  left: calc(63%);
  width: calc(3px);
  height: calc(3px);
  -webkit-animation-delay: 1189ms;
  animation-delay: 1189ms;
}

.night-background .scenery .star:nth-child(39) {
  opacity: 0.39;
  top: calc(20%);
  left: calc(95%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 1481ms;
  animation-delay: 1481ms;
}

.night-background .scenery .star:nth-child(40) {
  opacity: 0.26;
  top: calc(36%);
  left: calc(52%);
  width: calc(2px);
  height: calc(2px);
  -webkit-animation-delay: 1546ms;
  animation-delay: 1546ms;
}

.night-background .scenery .tree {
  position: fixed;
  background-image: url(palms.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
  right: 0;
  bottom: 0;
  top: 50%;
  left: 50%;
}

.night-background .scenery .night {
  position: relative;
  margin: auto;
  width: 80vw;
  height: 80vw;
  -webkit-transform: rotateZ(135deg);
  transform: rotateZ(135deg);
}

.night-background .scenery .shooting_star {
  position: absolute;
  left: 0%;
  top: 0%;
  height: 2px;
  background: linear-gradient(-45deg, #50a0ff, rgba(0, 0, 255, 0.1));
  border-radius: 99px;
  -webkit-animation: shooting 10000ms linear infinite;
  animation: shooting 10000ms linear infinite;
  opacity: 0;
}

.night-background .scenery .shooting_star:nth-child(1) {
  top: calc(36%);
  left: 0;
  -webkit-animation-delay: calc(4768ms);
  animation-delay: calc(4768ms);
}

.night-background .scenery .shooting_star:nth-child(2) {
  top: calc(28%);
  left: 0;
  -webkit-animation-delay: calc(5690ms);
  animation-delay: calc(5690ms);
}

.night-background .scenery .shooting_star:nth-child(3) {
  top: calc(56%);
  left: 0;
  -webkit-animation-delay: calc(17212ms);
  animation-delay: calc(17212ms);
}

.night-background .scenery .shooting_star:nth-child(4) {
  top: calc(76%);
  left: 0;
  -webkit-animation-delay: calc(21895ms);
  animation-delay: calc(21895ms);
}

.night-background .scenery .shooting_star:nth-child(5) {
  top: calc(65%);
  left: 0;
  -webkit-animation-delay: calc(11870ms);
  animation-delay: calc(11870ms);
}

.night-background .scenery .shooting_star:nth-child(6) {
  top: calc(30%);
  left: 0;
  -webkit-animation-delay: calc(38589ms);
  animation-delay: calc(38589ms);
}

.night-background .scenery .shooting_star:nth-child(7) {
  top: calc(37%);
  left: 0;
  -webkit-animation-delay: calc(38720ms);
  animation-delay: calc(38720ms);
}

.night-background .scenery .shooting_star:nth-child(8) {
  top: calc(82%);
  left: 0;
  -webkit-animation-delay: calc(52787ms);
  animation-delay: calc(52787ms);
}

.night-background .scenery .shooting_star:nth-child(9) {
  top: calc(68%);
  left: 0;
  -webkit-animation-delay: calc(34810ms);
  animation-delay: calc(34810ms);
}

.night-background .scenery .shooting_star:nth-child(10) {
  top: calc(59%);
  left: 0;
  -webkit-animation-delay: calc(57990ms);
  animation-delay: calc(57990ms);
}

@-webkit-keyframes star {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes star {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes shooting {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    width: 0;
  }
  50% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
    opacity: 0;
    width: calc(243px);
  }
}

@keyframes shooting {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    width: 0;
  }
  50% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(500px);
    transform: translateX(500px);
    opacity: 0;
    width: calc(243px);
  }
}
