[data-amplify-authenticator] {
  margin: 1rem;
  margin-top: 20vh;
}
.amplify-card {
  @include glass();
  color: white;
}
.amplify-button:not([data-variation="primary"]).amplify-field-group__control,
.amplify-button[data-variation="link"],
a.amplify-link {
  background: none;
  color: $amplify-button-text-color;
  border: none;
  &:hover {
    background: none;
    border: none;
    color: $amplify-button-hover-text-color;
    box-shadow: none;
  }
}
[data-amplify-authenticator] {
  .amplify-button {
    color: $amplify-button-text-color;
    background-color: $amplify-button-bg-color;
    border: 1px solid $amplify-button-border;
    width: auto;
  }

  .amplify-heading {
    color: white;
    font-weight: 400;
    margin-bottom: 1rem;
  }
  .amplify-text {
    font-weight: 200;
    color: white;
  }
  .amplify-select__wrapper {
    margin-right: 1rem;
  }
  option {
    background: black;
    color: $input-focus-text-color;
  }
  .amplify-select,
  input,
  button {
    border: none;
    border-radius: 4px !important;
    outline: none;
    background-color: $input-bg-color;
    border: 1px solid $input-border-color;
    transition: background-position 1s ease-in-out, opacity 0.5s ease-in-out,
      border-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out,
      background-color 0.5s ease-in-out, color 0.5s ease-in-out;

    &:hover,
    &:focus,
    &:active {
      background-position: 90%;
      border-color: $input-active-border-color;
      box-shadow: $input-active-box-shadow;
      background-color: $input-active-bg-color;
    }

    &:focus {
      border-color: $input-focus-border-color;
      color: $input-focus-text-color !important;
      background-color: $input-focus-bg-color;
    }
  }
  [data-amplify-form]:not(:last-child) {
    padding-bottom: 0.5rem;
  }
  .amplify-button[data-variation="primary"] {
    position: relative;

    //background: linear-gradient(left, #40a 0%, #a40 40%, #40a 60%, #088 100%);
    background: linear-gradient(
      $amplify-button-primary-gradient-dir,
      rgba(#088, 0.7) 0%,
      rgba(#40a, 0.7) 40%,
      rgba(#a40, 0.7) 60%,
      rgba(#40a, 0.7) 100%
    );
    background-size: 300%;
    background-position: 1%;
    overflow: hidden;
    border-color: $amplify-button-border-color-primary;
    color: $amplify-button-text-color-primary;
    background-repeat: no-repeat;
    font-weight: 400 !important;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      background-position: 99%;
      border-color: $amplify-button-active-border-color-primary;
      color: $amplify-button-active-text-color-primary;
      box-shadow: $amplify-button-active-box-shadow-primary;
    }
    &[disabled] {
      border-color: $amplify-button-border-color-dis-primary;
      color: $amplify-button-text-color-dis-primary;
      opacity: 0.7;
      background: linear-gradient(to right, #444 0%, #888 100%);
      &:hover,
      &:focus,
      &:active {
        border-color: none;
        color: none;
        box-shadow: none;
      }
    }
  }
  --amplify-colors-background-secondary: #eee;
  & [data-state="inactive"][role="tab"] {
    background-color: transparent;
    color: $text-color-tab-inactive;
  }

  .amplify-text[data-variation="error"] {
    color: #f8a;
    font-weight: 200;
  }
  .amplify-alert[data-variation="error"] {
    padding: 1rem;
    border-radius: 4px;
    background-color: rgba(255, 0, 0, 0.2);
    border: 1px solid rgba(255, 0, 0, 0.2);
    color: $amplify-field-group-text-color-error;
    .amplify-button[data-variation="link"] {
      color: $amplify-field-group-text-color-error;
      &:hover {
        color: $amplify-alert-amplify-button-hover-text-color-error;
      }
    }
  }
  .amplify-label {
    color: #ccc;
  }
  .amplify-field-group {
    button {
      display: none;
    }
    [aria-invalid="true"].amplify-select,
    [aria-invalid="true"].amplify-input,
    [aria-invalid="true"].amplify-textarea {
      background-color: rgba(255, 0, 0, 0.2);
      border: 1px solid rgba(255, 0, 0, 0.2);
      color: $amplify-field-group-text-color-error;
      &:hover,
      &::placeholder {
        color: $amplify-field-group-text-color-error;
      }
    }
    input {
      color: $amplify-field-group-text-color-input;
      border-radius: 4px !important;
      cursor: pointer;
      &:focus::placeholder {
        color: $amplify-field-group-focus-text-color;
      }
      &::placeholder {
        font-weight: 200;
        color: $amplify-field-group-placeholder-text-color;
      }
    }
  }

  [data-amplify-router] {
    @include glass();
    overflow: hidden;
  }
  .amplify-tabs {
    border: none;
  }

  .amplify-tabs-item {
    font-weight: 200;
    color: $amplify-tabs-item-text-color-glow;
    &[data-state="active"] {
      text-shadow: 0 0 2em $amplify-tabs-item-text-shadow-color-glow;
      font-weight: 400;
    }
  }
}
